import ClusterConstants from "./cluster-constants";
import { decimal2Hex, hex2Decimal, minMax } from "./utils";
// types
import type { ReadonlyTuple, WritableDeep } from "type-fest";
import type { IncapsCluster0300 } from "../types/cluster";
import type { ColorHex, ColorPrimaries, ColorPrimariesWithZ, ColorRGB, ColorXY } from "../types/misc";

type ConversionMatrix = ReadonlyTuple<ReadonlyTuple<number, 3>, 3>;

const COLOR_CLUSTER_PROPS = {
	rX: ClusterConstants.D0300.Attributes.PrimaryRedX,
	rY: ClusterConstants.D0300.Attributes.PrimaryRedY,
	gX: ClusterConstants.D0300.Attributes.PrimaryGreenX,
	gY: ClusterConstants.D0300.Attributes.PrimaryGreenY,
	bX: ClusterConstants.D0300.Attributes.PrimaryBlueX,
	bY: ClusterConstants.D0300.Attributes.PrimaryBlueY,
} as const;

const DEFAULT_COLOR_PRIMARIES = {
	rX: 45874,
	rY: 19660,
	gX: 7208,
	gY: 53738,
	bX: 7864,
	bY: 5242,
} as const satisfies ColorPrimaries;

// White-point Illuminant-E
const WHITE_POINT = {
	wX: 0.33, // 1 / 3,
	wY: 0.33, // 1 / 3
} as const;

export const KELVIN_UNIT = "K";

/** `6536 K` (rounded) */
export const DEFAULT_COLOR_TEMPERATURE_MIRED_MIN = 153;
/** `2000 K` */
export const DEFAULT_COLOR_TEMPERATURE_MIRED_MAX = 500;

export const getColorPrimariesFromCluster = (cluster: Readonly<IncapsCluster0300>): ColorPrimaries => {
	const colorPrimaries = {...DEFAULT_COLOR_PRIMARIES}; // copy
	const primariesCountValid = cluster.hasOwnProperty(ClusterConstants.D0300.Attributes.PrimariesCount) && cluster[ClusterConstants.D0300.Attributes.PrimariesCount] === 3;
	for (const prop of Object.keys(colorPrimaries)) {
		const clusterValue = cluster[COLOR_CLUSTER_PROPS[prop]];
		if (primariesCountValid && typeof clusterValue === "number" && clusterValue > 0) {
			colorPrimaries[prop] = clusterValue;
		}
		colorPrimaries[prop] /= 65536;
	}
	return colorPrimaries;
};

const getColorPrimariesWithZ = (colorPrimaries: ColorPrimaries): ColorPrimariesWithZ => ({
	...colorPrimaries,
	rZ: 1 - colorPrimaries.rX - colorPrimaries.rY,
	gZ: 1 - colorPrimaries.gX - colorPrimaries.gY,
	bZ: 1 - colorPrimaries.bX - colorPrimaries.bY,
});

const getConversionMatrix = (colorPrimaries: ColorPrimaries): ConversionMatrix => {
	const { rX, rY, rZ, gX, gY, gZ, bX, bY, bZ } = getColorPrimariesWithZ(colorPrimaries);

	const { wX, wY } = WHITE_POINT;

	const D = (rX - bX) * (gY - bY) - (rY - bY) * (gX - bX);
	const U = (wX - bX) * (gY - bY) - (wY - bY) * (gX - bX);
	const V = (rX - bX) * (wY - bY) - (rY - bY) * (wX - bX);
	const u = U / D;
	const v = V / D;
	const w = 1 - u - v;

	// Create the conversion matrix for RGB to XYZ
	return [
		[
			u * (rX / wY),
			v * (gX / wY),
			w * (bX / wY),
		],
		[
			u * (rY / wY),
			v * (gY / wY),
			w * (bY / wY),
		],
		[
			u * (rZ / wY),
			v * (gZ / wY),
			w * (bZ / wY),
		],
	] as const;
};

export const convertColorXYToColorRGB = (currentX: number, currentY: number, colorPrimaries: ColorPrimaries): ColorRGB => {
	const matrix = getConversionMatrix(colorPrimaries);

	// Now we need to create the inverse matrix for XYZ to RGB; calculate the determinant
	const determinant =
		matrix[0][0] * (matrix[1][1] * matrix[2][2] - matrix[2][1] * matrix[1][2]) -
		matrix[0][1] * (matrix[1][0] * matrix[2][2] - matrix[1][2] * matrix[2][0]) +
		matrix[0][2] * (matrix[1][0] * matrix[2][1] - matrix[1][1] * matrix[2][0]);

	/* eslint-disable @stylistic/no-multi-spaces */
	// Use the determinant to calculate the inverse matrix
	const invMatrix: WritableDeep<ConversionMatrix> = [[0, 0, 0], [0, 0, 0], [0, 0, 0]];
	invMatrix[0][0] =  (matrix[1][1] * matrix[2][2] - matrix[2][1] * matrix[1][2]) / determinant;
	invMatrix[1][0] = -(matrix[1][0] * matrix[2][2] - matrix[1][2] * matrix[2][0]) / determinant;
	invMatrix[2][0] =  (matrix[1][0] * matrix[2][1] - matrix[2][0] * matrix[1][1]) / determinant;
	invMatrix[0][1] = -(matrix[0][1] * matrix[2][2] - matrix[0][2] * matrix[2][1]) / determinant;
	invMatrix[1][1] =  (matrix[0][0] * matrix[2][2] - matrix[0][2] * matrix[2][0]) / determinant;
	invMatrix[2][1] = -(matrix[0][0] * matrix[2][1] - matrix[2][0] * matrix[0][1]) / determinant;
	invMatrix[0][2] =  (matrix[0][1] * matrix[1][2] - matrix[0][2] * matrix[1][1]) / determinant;
	invMatrix[1][2] = -(matrix[0][0] * matrix[1][2] - matrix[1][0] * matrix[0][2]) / determinant;
	invMatrix[2][2] =  (matrix[0][0] * matrix[1][1] - matrix[1][0] * matrix[0][1]) / determinant;
	/* eslint-enable @stylistic/no-multi-spaces */

	const x = currentX / 65536;
	const y = currentY / 65536;
	const z = 1 - x - y;

	// tristimulus values
	const Y = 1;
	const X = Y * x / y;
	const Z = Y * z / y;

	let R = X * invMatrix[0][0] + Y * invMatrix[0][1] + Z * invMatrix[0][2];
	let G = X * invMatrix[1][0] + Y * invMatrix[1][1] + Z * invMatrix[1][2];
	let B = X * invMatrix[2][0] + Y * invMatrix[2][1] + Z * invMatrix[2][2];

	const max = Math.max(R, G, B);

	if (max > 1) {
		R /= max;
		G /= max;
		B /= max;
	}

	R = Math.max(0, R);
	G = Math.max(0, G);
	B = Math.max(0, B);

	if (Number.isNaN(R) || Number.isNaN(G) || Number.isNaN(B)) {
		return {
			r: 255,
			g: 15,
			b: 0,
		} as const;
	} else {
		return {
			r: Math.round(R * 255),
			g: Math.round(G * 255),
			b: Math.round(B * 255),
		} as const;
	}
};

export const convertColorRGBToColorXY = (color: ColorRGB, colorPrimaries: ColorPrimaries): ColorXY => {
	const matrix = getConversionMatrix(colorPrimaries);

	const R = color.r / 255;
	const G = color.g / 255;
	const B = color.b / 255;

	// tristimulus values
	const X = R * matrix[0][0] + G * matrix[0][1] + B * matrix[0][2];
	const Y = R * matrix[1][0] + G * matrix[1][1] + B * matrix[1][2];
	const Z = R * matrix[2][0] + G * matrix[2][1] + B * matrix[2][2];

	let x = 0;
	let y = 0;
	// let z = 0;
	if (X + Y + Z > 0) {
		x = X / (X + Y + Z);
		y = Y / (X + Y + Z);
		// z = Z / (X + Y + Z);
		// z = 1 - x - y!!!!
	}

	return {
		currentX: Math.round(x * 65536),
		currentY: Math.round(y * 65536),
	} as const;
};

export const rgbToHex = (colorObj: ColorRGB): ColorHex => (
	`#${[colorObj.r, colorObj.g, colorObj.b].map((color) => (decimal2Hex(color, 2))).join("")}`
);

export const hexToRgb = (colorCode: ColorHex): ColorRGB => ({
	r: hex2Decimal(colorCode.substring(1, 3)),
	g: hex2Decimal(colorCode.substring(3, 5)),
	b: hex2Decimal(colorCode.substring(5, 7)),
});

const scale = (value: number, oldMin: number, oldMax: number, newMin: number, newMax: number): number => (
	newMin + ((newMax - newMin) * ((value - oldMin) / (oldMax - oldMin)))
);

/**
 * https://de.wikipedia.org/wiki/Mired
 */
export const convertMiredToKelvin = (miredTemp: number) => (
	1_000_000 / miredTemp
);

/**
 * https://de.wikipedia.org/wiki/Mired
 * http://www.tannerhelland.com/4435/convert-temperature-rgb-algorithm-code/
 */
export const convertMiredToHex = (miredTemp: number): ColorHex => {
	let red: number, green: number, blue: number;
	const kelvin = 1000000 / miredTemp;
	const scaled = Math.exp(scale(Math.log(kelvin), 7.600902459542082, 8.785080636539838, 7.549609165154532, 9.903487552536127)); // Math.log: 2000, 6536, 1900, 20000
	const temp = scaled / 100;

	if (temp <= 66) {
		red = 255;
	} else {
		red = minMax(Math.floor(329.698727466 * Math.pow(temp - 60, -0.1332047592)));
	}

	if (temp <= 66) {
		green = minMax(Math.floor(99.4708025861 * Math.log(temp) - 161.1195681661));
	} else {
		green = minMax(Math.floor(288.1221695283 * Math.pow(temp - 60, -0.0755148492)));
	}

	if (temp >= 66) {
		blue = 255;
	} else if (temp <= 19) {
		blue = 0;
	} else {
		blue = minMax(Math.floor(138.5177312231 * Math.log(temp - 10) - 305.0447927307));
	}

	const color = {
		r: red,
		g: green,
		b: blue,
	} as const satisfies ColorRGB;

	return rgbToHex(color);
};

import { Capacitor } from "@capacitor/core";
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import ClusterConstants from "./cluster-constants";
import type { Config } from "@transistorsoft/capacitor-background-geolocation";
/**
 * Constant file - Contaning basically enum values.
 * @constant constants
 */
const constants = {
	Platform: {
		Android: "android",
		iOS: "ios",
		Web: "web",
	},
	Channel: {
		Hornbach: "hornbach",
		Ozom: "ozom",
		Roc: "roc",
	},
	RocIdUnderConstruction: "FFFF",
	RocIdDeviceInfo: {
		Hide: "hide",
		DeviceAttributes: "device_attributes",
		RocIdPrivate: "rocid_private",
		RocIdPublic: "rocid_public",
	},
	Appearance: {
		Light: "light",
		Dark: "dark",
		System: "system",
	},
	TableName: {
		RocIdDict: "rocid_dict",
		Grules: "grules",
		GrulesAdvanced: "grules_advanced",
		QuickViewConfig: "quickview_config",
	},
	FoodWeightDefaultValue: 10,
	TempDefaultValue: 20,
	TempUnit: {
		Celsius: "C",
		Fahrenheit: "F",
	},
	Notification: {
		Type: {
			DeviceDetails: "device_details",
		},
	},
	Rule: {
		Mode: {
			AddScene: "addScene",
			Add: "add",
			Edit: "edit",
			Duplicate: "duplicate",
		},
		Type: {
			Scene: "scene",
			Template: "template",
			Scheduler: "scheduler",
			Advanced: "advanced",
		},
		ParamType: {
			Check: "check",
			Action: "action",
		},
		ItemsType: {
			Checks: "checks",
			Actions: "actions",
		},
		AdvancedParamCategory: {
			Device: "device",
			Gateway: "gateway",
			Notification: "notification",
			Scene: "scene",
			Time: "time",
			VirtualDevice: "virtual_device",
		},
		TemplateParamId: {
			SmartMode: "smartmode",
		},
		AdvancedRuleTemplateId: {
			CheckThermostatState: "check_thermostat_state",
			SetThermostatMode: "set_thermostat_mode",
		},
		AdvancedRuleErrorMode: {
			BlockedBy: "blockedBy",
			MaxOccurrences: "maxOccurrences",
			RequiresAdditionalChecks: "requiresAdditionalChecks",
		},
		TimezoneReference: {
			Home: "home",
			Manual: "manual",
		},
		VirtualDeviceId: {
			AllCameras: "80C07D1D00000004",
			AllSirens: "80C07D1D00000012",
			Notification: "80C07D1D00000013",
			SunriseSunset: "80C07D1D00000020", // TODO: check naming
			CloudBackup: "80C07D1D00000021",
		},
		RuleNameMaxLength: 128,
	},
	SubDeviceType: {
		Parent: 0,
		Child: 1,
	},
	EpDeviceType: {
		None: 0b00,
		Device: 0b01,
		Endpoint: 0b10,
	},
	SmartModes: ["off", "home", "night", "away"],
	HomeConnect: {
		OperationState: {
			Inactive: 0,
			Ready: 1,
			DelayedStart: 2,
			Run: 3,
			Pause: 4,
			ActionRequired: 5,
			Finished: 6,
			Error: 7,
			Aborting: 8,
		},
		SupportedOperationPowerState: {
			Off: 0b0000_0001,
			On: 0b0000_0010,
			Standby: 0b0000_0100,
		},
		DoorLockStates: [
			{
				value: 0,
				l10n: "clusters.DFFAC.doorLockStates.open",
			},
			{
				value: 1,
				l10n: "clusters.DFFAC.doorLockStates.closed",
			},
			{
				value: 2,
				l10n: "clusters.DFFAC.doorLockStates.locked",
			},
		],
		AvailableOperationState: [
			{
				value: 0,
				bitFlag: 0b0000_0000_0000_0000_0000_0000_0000_0001,
				l10n: "clusters.DFFAC.operationStateModes.inactive",
			},
			{
				value: 1,
				bitFlag: 0b0000_0000_0000_0000_0000_0000_0000_0010,
				l10n: "clusters.DFFAC.operationStateModes.ready",
			},
			{
				value: 2,
				bitFlag: 0b0000_0000_0000_0000_0000_0000_0000_0100,
				l10n: "clusters.DFFAC.operationStateModes.delayedStart",
			},
			{
				value: 3,
				bitFlag: 0b0000_0000_0000_0000_0000_0000_0000_1000,
				l10n: "clusters.DFFAC.operationStateModes.run",
			},
			{
				value: 4,
				bitFlag: 0b0000_0000_0000_0000_0000_0000_0001_0000,
				l10n: "clusters.DFFAC.operationStateModes.pause",
			},
			{
				value: 5,
				bitFlag: 0b0000_0000_0000_0000_0000_0000_0010_0000,
				l10n: "clusters.DFFAC.operationStateModes.actionRequired",
			},
			{
				value: 6,
				bitFlag: 0b0000_0000_0000_0000_0000_0000_0100_0000,
				l10n: "clusters.DFFAC.operationStateModes.finished",
			},
			{
				value: 7,
				bitFlag: 0b0000_0000_0000_0000_0000_0000_1000_0000,
				l10n: "clusters.DFFAC.operationStateModes.error",
			},
			{
				value: 8,
				bitFlag: 0b0000_0000_0000_0000_0000_0001_0000_0000,
				l10n: "clusters.DFFAC.operationStateModes.aborting",
			},
		],
	},
	ThermostatModes: [
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeOff,
			bitFlag: 0b0000_0000_0000_0000_0000_0000_0000_0001,
			l10n: "off",
			image: "thermostat/thermostat_mode_Off.svg",
			setpointDisabled: true,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeStandby,
			bitFlag: 0b0000_0000_0000_0001_0000_0000_0000_0000,
			l10n: "standby",
			image: "thermostat/thermostat_mode_c.svg",
			setpointDisabled: true,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeHeat,
			bitFlag: 0b0000_0000_0000_0000_0000_0000_0000_0010,
			l10n: "heat",
			image: "thermostat/thermostat_mode_b.svg",
			setpointDisabled: false,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeCool,
			bitFlag: 0b0000_0000_0000_0000_0000_0000_0000_0100,
			l10n: "cool",
			image: "thermostat/thermostat_mode_a.svg",
			setpointDisabled: false,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeAuto,
			bitFlag: 0b0000_0000_0000_0000_0000_0000_0000_1000,
			l10n: "auto",
			image: "thermostat/thermostat_mode_d.svg",
			setpointDisabled: false,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeAuxiliary,
			bitFlag: 0b0000_0000_0000_0000_0000_0000_0001_0000,
			l10n: "auxiliary",
			image: "thermostat/thermostat_mode_e.svg",
			setpointDisabled: false,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeResume,
			bitFlag: 0b0000_0000_0000_0000_0000_0000_0010_0000,
			l10n: "resume",
			image: "thermostat/thermostat_mode_f.svg",
			setpointDisabled: false,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeFan,
			bitFlag: 0b0000_0000_0000_0000_0000_0000_0100_0000,
			l10n: "fan",
			image: "thermostat/thermostat_mode_g.svg",
			setpointDisabled: true,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeFurnace,
			bitFlag: 0b0000_0000_0000_0000_0000_0000_1000_0000,
			l10n: "furnace",
			image: "thermostat/thermostat_mode_h.svg",
			setpointDisabled: false,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeDry,
			bitFlag: 0b0000_0000_0000_0000_0000_0001_0000_0000,
			l10n: "dry",
			image: "thermostat/thermostat_mode_i.svg",
			setpointDisabled: true,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeMoist,
			bitFlag: 0b0000_0000_0000_0000_0000_0010_0000_0000,
			l10n: "moist",
			image: "thermostat/thermostat_mode_l.svg",
			setpointDisabled: true,
		},
		{
			value: ClusterConstants.DFF80.CmdPayloads.SetModeDisinfection,
			bitFlag: 0b0000_0000_0000_0000_1000_0000_0000_0000,
			l10n: "disinfection",
			image: "thermostat/thermostat_mode_m.svg",
			setpointDisabled: true,
		},
	],
	DaysOfWeek: [1, 2, 3, 4, 5, 6, 7],
	MomitModes: [
		{
			value: 0,
			l10n: "clusters.DFF69.modes.off",
		},
		{
			value: 1,
			l10n: "clusters.DFF69.modes.manual",
		},
		{
			value: 2,
			l10n: "clusters.DFF69.modes.schedule",
		},
		{
			value: 3,
			l10n: "clusters.DFF69.modes.offline",
		},
	],
	FanModes: [
		{
			value: 1,
			bitFlag: 0b0000_0001,
			l10n: "clusters.DFF99.modes.nature",
		},
		{
			value: 2,
			bitFlag: 0b0000_0010,
			l10n: "clusters.DFF99.modes.sleep",
		},
		{
			value: 3,
			bitFlag: 0b0000_0100,
			l10n: "clusters.DFF99.modes.fresh",
		},
		{
			value: 4,
			bitFlag: 0b0000_1000,
			l10n: "clusters.DFF99.modes.smart",
		},
	],
	ColorTemplates: ["#fff5d7", "#ebf7ff", "#0000ff", "#ff0000", "#ffff00", "#00ff00"],
	ColorTemperatureTemplates: ["#ff9329", "#ffd6aa", "#fff1e0", "#ffffff", "#c9e2ff", "#409cff"],
	Co2LevelRange: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 15000, 20000, 25000, 30000],
	VocLevelRange: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000],
	PowerConsumptionRange: [0, 2, 4, 6, 8, 10, 12, 15, 20, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 2000, 2500, 3000],
	PowerProductionRange: [3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500, 10000, 10500, 11000, 11500, 12000, 12500, 13000, 13500, 14000, 14500, 15000],
	HistoryFilterTypes: {
		Device: "device",
	},
	GeneralCmdIds: {
		ReadAttribute: "00",
		WriteAttribute: "02",
	},
	DataType: {
		Boolean: "10", // true/false
		Bitmap8Bit: "18", // "00"
		UInt8Bit: "20", // "00"
		UInt16Bit: "21", // "0000"
		UInt24Bit: "22", // "000000"
		SInt24Bit: "2A", // "000000"
		Enum8Bit: "30", // "00"
	},
	Caps: {
		Incaps: "incaps",
		Outcaps: "outcaps",
	},
	Gateway: {
		Kind: {
			Gateway: "gateway",
			C2C: "c2c",
			GPS: "gps",
		},
		Level: {
			Owner: "owner",
			User: "user",
			Pending: "pending",
		},
		RBAC: {
			Owner: 0b00000001,
			User: 0b00000010,
		},
		Status: {
			Ready: "ready",
			Unreachable: "unreachable",
			Pairing: "pairing",
		},
		Sku: {
			Evvr: "evvr",
			Tuya: "tuya",
			VirtualGateway: "virtual_gateway",
		},
		Type: {
			Evvr: "evvr",
			Sercom: "sercom",
		},
		SunTimeOffset: {
			Min: -120,
			Max: 120,
			Step: 5,
		},
		ReportId: {
			SmartMode: "FFF1",
			Timezone: "FFF2",
			TemperatureUnit: "FFF3",
		},
		GatewayOperation: {
			Backup: "backup",
			Restore: "restore",
		},
		DeviceType: {
			Zigbee: "ZigBee",
			Zwave: "Z-Wave",
		},
		GatewayOperationMode: {
			Idle: 0,
			Backup: 1,
			Restore: 2,
		},
		GatewayBackupRestoreStatus: {
			SuccessIdle: 0,
			Failed: 1,
			Running: 2,
		},
		GatewayBackupRestoreError: {
			RestoreError: 2,
			WrongPassword: 3,
			Idle: 0xFF,
		},
		ZigbeeRouting: {
			SourceRouting: "1040",
			TableRouting: "1140",
		},
	},
	Device: {
		Property: {
			PowerLevelStatus: "FF00",
			ReachableStatus: "FF01",
			HideActionButton: "FF11",
		},
		Value: {
			PowerLevelStatus: {
				Bat: "bat",
				LowBat: "low_bat",
			},
			ReachableStatus: {
				Reachable: "reachable",
				Unreachable: "unreachable",
			},
		},
		Attributes: {
			Property: {
				ZCLVersion: "0000",
				ApplicationVersion: "0001",
				StackVersion: "0002",
				HWVersion: "0003",
				ManufacturerName: "0004",
				ModelIdentifier: "0005",
				FirmwareVersion: "0006",
				PowerSource: "0007",
				DeviceCustomConfigVersion: "FF00", // TODO: check existence
				TuyaProductId: "FF03",
				DeviceType: "Device Type",
			},
			Value: {
				PowerSource: {
					Unknown: "00",
					Mains1Phase: "01",
					Mains3Phase: "02",
					Battery: "03",
					DCSource: "04",
					EmergencyMainsConstantlyPowered: "05",
					EmergencyMainsTransferSwitch: "06",
				},
				DeviceType: {
					/** @deprecated */
					C2gActions: "c2g_actions",
					CameraActions: "cameraActions",
					Eglo: "eglo",
					Everhome: "everhome",
					Ezviz: "ezviz",
					Comet: "comet",
					Coulisse: "coulisse",
					Dreame: "dreame",
					Gardena: "gardena",
					GeofenceActions: "geofenceActions",
					GroupActions: "groupActions",
					HomeConnect: "home_connect",
					Hue: "hue",
					HueRemote: "hue_remote",
					Indego: "indego",
					MomitActions: "momitActions",
					MotionActions: "motionActions",
					Mova: "mova",
					Mystrom: "mystrom",
					Netatmo: "netatmo",
					NukiActions: "nukiActions",
					Ondilo: "ondilo",
					Shelly: "shelly",
					Tado: "tado",
					Tuya: "tuya",
					Wiz: "wiz",
					Yale: "yale",
					ZigbeeActions: "zbActions",
					ZwaveActions: "zwaveActions",
				},
			},
		},
		UnsupportedAttributes: ["error:86", "NULL"],
	},
	PairingCategoryType: {
		Manufacturer: "manufacturer",
		DeviceType: "type",
	},
	PairingDeviceCategory: {
		Alexa: "alexa",
		Device: "device",
		Eglo: "eglo",
		Everhome: "everhome",
		Ezviz: "ezviz",
		Comet: "comet",
		Coulisse: "coulisse",
		Dreame: "dreame",
		Gardena: "gardena",
		Ghome: "ghome",
		HomeConnect: "home_connect",
		Hue: "hue",
		HueRemote: "hue_remote",
		Indego: "indego",
		Motion: "motion",
		Mova: "mova",
		Mystrom: "mystrom",
		Netatmo: "netatmo",
		Nuki: "nuki",
		Ondilo: "ondilo",
		Shelly: "shelly",
		Tado: "tado",
		Tuya: "tuya",
		Wifi: "wifi",
		Wiz: "wiz",
		Yale: "yale",
		Zigbee: "zigbee",
		Zwave: "zwave",
	},
	PairState: {
		CodePending: "code_pending",
		CodeError: "code_error",
		Error: "error",
		NotApproved: "not_approved", // Deprecated?
	},
	Units: {
		Percent: "%",
		Degree: "°",
		Watt: "W",
		KiloWattHour: "kWh",
		Lux: "lx",
		HectoPascal: "hPa",
		PartsPerMillion: "ppm",
		PartsPerBillion: "ppb",
		Gram: "g",
		MicroGramPerCubicMeter: "µg/m³",
		OydoReductionPotential: "mV",
		SaltCocentration: "mg/L",
	},
	InvalidValue: "--",
	Tabs: {
		Devices: {
			Devices: "devices",
			Rooms: "rooms",
		},
		Rules: {
			Scenes: "scenes",
			Templates: "templates",
			Scheduler: "scheduler",
			Advanced: "advanced",
		},
		History: {
			All: "all",
			Event: "event",
			Rule: "rule",
			Alert: "alert",
			Report: "report",
		},
	},
	HistoryTags: {
		Alert: "alert",
		Event: "event",
		Rule: "rule",
		Scene: "scene",
		Gateway: "gateway",
	},
	MotionSensitivitiesTuya: [
		{
			value: "00",
			l10n: "clusters.DC38A.category.motion.motionSensitivities.low",
		},
		{
			value: "01",
			l10n: "clusters.DC38A.category.motion.motionSensitivities.medium",
		},
		{
			value: "02",
			l10n: "clusters.DC38A.category.motion.motionSensitivities.high",
		},
	],
	PirSensitivitiesTuya: [
		{
			value: "00",
			l10n: "clusters.DC38A.category.motion.pirSensitivities.low",
		},
		{
			value: "01",
			l10n: "clusters.DC38A.category.motion.pirSensitivities.medium",
		},
		{
			value: "02",
			l10n: "clusters.DC38A.category.motion.pirSensitivities.high",
		},
	],
	SupportedRecordingModes: [
		{
			value: 1,
			bitFlag: 0b01,
			l10n: "clusters.DC38A.category.recording.events",
		},
		{
			value: 2,
			bitFlag: 0b10,
			l10n: "clusters.DC38A.category.recording.continuous",
		},
	],
	NukiDeviceTypes: {
		DoorLock: 0,
		Opener: 2,
	},
	ParamEditorDataTypes: {
		Decimal: "Decimal",
		Hex: "Hex",
	},
	MowerStates: {
		InDock: 1,
		InLawn: 2,
		Paused: 3,
		ReturningToDock: 4,
		ServiceMode: 5,
		SoftwareUpdateMode: 6,
		LowPowerMode: 7,
		LeavingDock: 8,
		Unknown: 65535, // 0xFFFF
	},
	MowerStatesInLawn: [2, 4, 7, 8], // see `Constants.MowerStates`
	AvailableMowerModes: [
		{
			value: 2, // Constants.MowerStates.InLawn
			bitFlag: 0b0000_0000_0000_0001,
			l10n: "clusters.DFF84.mow",
		},
		{
			value: 1, // Constants.MowerStates.InDock
			bitFlag: 0b0000_0000_0000_0010,
			l10n: "clusters.DFF84.dock",
		},
		{
			value: 3, // Constants.MowerStates.Paused
			bitFlag: 0b0000_0000_0000_0100,
			l10n: "clusters.DFF84.pause",
		},
	],
	AvailableMowerActionModes: [
		{
			value: ClusterConstants.DFF84.CmdPayloads.Mow,
			bitFlag: 0b0000_0000_0000_0001,
			l10n: "clusters.DFF84.mow",
		},
		{
			value: ClusterConstants.DFF84.CmdPayloads.Dock,
			bitFlag: 0b0000_0000_0000_0010,
			l10n: "clusters.DFF84.dock",
		},
		{
			value: ClusterConstants.DFF84.CmdPayloads.Pause,
			bitFlag: 0b0000_0000_0000_0100,
			l10n: "clusters.DFF84.pause",
		},
	],
	AvailableHeaterLevel: ["1", "2", "3"],
	ColorModesTuya: [
		{
			id: 0,
			value: "00",
			l10n: "clusters.DFF00.whiteMode",
		},
		{
			id: 1,
			value: "01",
			l10n: "clusters.DFF00.colorMode",
		},
	],
	FirmwareStatus: {
		UnknownStatus: 0,
		UpdateAvailable: 1,
		Updating: 2,
		UpdateFailed: 3,
		UpToDate: 4,
		UpdateScheduled: 5,
	},
	VaccumModes: [
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.Standby,
			l10n: "standBy",
			isVisibleOnBasicView: false,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.Random,
			l10n: "random",
			isVisibleOnBasicView: false,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.Smart,
			l10n: "auto",
			isVisibleOnBasicView: true,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.WallFollow,
			l10n: "wallFollow",
			isVisibleOnBasicView: true,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.Mop,
			l10n: "mop",
			isVisibleOnBasicView: true,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.Spiral,
			l10n: "spiral",
			isVisibleOnBasicView: true,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.LeftSpiral,
			l10n: "leftSpiral",
			isVisibleOnBasicView: false,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.RightSpiral,
			l10n: "rightSpiral",
			isVisibleOnBasicView: false,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.RightBow,
			l10n: "rightBow",
			isVisibleOnBasicView: false,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.LeftBow,
			l10n: "leftBow",
			isVisibleOnBasicView: false,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.PartialBow,
			l10n: "partialBow",
			isVisibleOnBasicView: false,
		},
		{
			value: ClusterConstants.DFF97.VaccumModeCmdPayloads.ModeD,
			l10n: "modeD",
			isVisibleOnBasicView: true,
		},
	],
	SuctionModes: [
		{
			value: ClusterConstants.DFF97.SuctionModeCmdPayloads.Strong,
			l10n: "strong",
			isVisibleOnBasicView: true,
		},
		{
			value: ClusterConstants.DFF97.SuctionModeCmdPayloads.Normal,
			l10n: "normal",
			isVisibleOnBasicView: true,
		},
		{
			value: ClusterConstants.DFF97.SuctionModeCmdPayloads.Quiet,
			l10n: "quiet",
			isVisibleOnBasicView: false,
		},
	],
	SelfCheckStatus: {
		CheckSuccess: 0,
		CheckFailure: 1,
		Checking: 2,
		Unknown: 3,
	},
	ValveActivity: {
		Closed: 0,
		ManualWatering: 1,
		ScheduledWatering: 2,
	},
	ValveStatus: {
		Warning: 1,
		Error: 2,
		Unavailable: 3,
	},
	DeviceBatteryStatus: {
		Low: 1,
		ReplaceNow: 2,
	},
	NotificationType: {
		Unknown: 0,
		Locked: 1,
		Unlocked: 2,
		Open: 3,
		Closed: 4,
		Ajar: 5,
		Locking: 6,
		Unlocking: 7,
	},
	DoorLockPasswordStatus: [
		{
			value: 0,
			l10n: "deleted",
		},
		{
			value: 1,
			l10n: "pending",
		},
		{
			value: 2,
			l10n: "active",
		},
		{
			value: 3,
			l10n: "inactive",
		},
		{
			value: 4,
			l10n: "deleted",
		},
		{
			value: 5,
			l10n: "error",
		},
	],
	DoorLockFilterPhases: [0, 1, 4],
	DoorLockKeypadType: {
		FiveDigits: "fiveDigits",
		TenDigits: "tenDigits",
	},
	DoorLockOfflinePwdTypes: {
		MultiTime: 0,
		OneTime: 1,
	},
	ZwaveSecurityLevel: {
		Unsecure: 0,
		Secure: 1,
	},
	ZwaveSecurityLevels: [
		{
			value: 1,
			l10n: "s22",
		},
		{
			value: 2,
			l10n: "s21",
		},
		{
			value: 3,
			l10n: "s20",
		},
		{
			value: 4,
			l10n: "s0",
		},
	],
	NightVisionModes: [
		{
			value: "00",
			l10n: "clusters.DC38A.category.moreOptions.auto",
		},
		{
			value: "01",
			l10n: "clusters.DC38A.category.moreOptions.irMode",
		},
		{
			value: "02",
			l10n: "clusters.DC38A.category.moreOptions.colorMode",
		},
	],
	FSDTemperatureModes: [
		{
			value: 0,
			l10n: "clusters.DFF9D.temperatureValues.low",
		},
		{
			value: 1,
			l10n: "clusters.DFF9D.temperatureValues.medium",
		},
		{
			value: 2,
			l10n: "clusters.DFF9D.temperatureValues.high",
		},
	],
	GYDPirSensitivityMode: [
		{
			value: 0,
			l10n: "clusters.DFF9D.gydPirSensitivityMode.low",
		},
		{
			value: 1,
			l10n: "clusters.DFF9D.gydPirSensitivityMode.middle",
		},
		{
			value: 2,
			l10n: "clusters.DFF9D.gydPirSensitivityMode.high",
		},
	],
	AmbientLightMode: [
		{
			value: 1,
			l10n: "clusters.DFF9D.ambientLightMode.dark",
		},
		{
			value: 2,
			l10n: "clusters.DFF9D.ambientLightMode.night",
		},
		{
			value: 3,
			l10n: "clusters.DFF9D.ambientLightMode.evening",
		},
		{
			value: 4,
			l10n: "clusters.DFF9D.ambientLightMode.dusk",
		},
		{
			value: 5,
			l10n: "clusters.DFF9D.ambientLightMode.day",
		},
	],
	TouchDimmerSwitchLightTypes: [
		{
			value: 0,
			l10n: "clusters.DFF9D.typeValues.incandescent",
		},
		{
			value: 1,
			l10n: "clusters.DFF9D.typeValues.halogen",
		},
		{
			value: 2,
			l10n: "clusters.DFF9D.typeValues.led",
		},
	],
	BulbType: {
		ColorTemperature: 1,
		ColorXY: 2,
	},
	ColorCapabilities: {
		HueSaturation: 1,
		EnhancedHue: 2, // Requires "HueSaturation"
		ColorLoop: 4, // Requires "EnhancedHue"
		ColorXY: 8,
		ColorTemperature: 16,
	},
	DoorLockCapabilities: {
		TemporaryPasswords: 1,
		DynamicPasswords: 2,
		OfflinePasswords: 4,
	},
	SettingsPageTypes: {
		Camera: "camera",
		Geofence: "geofence",
		Sercomm: "sercomm",
		Shades: "shades",
		Steinel: "steinel",
		Vaccum: "vaccum",
	},
	NetatmoCameraNotificationIds: [
		ClusterConstants.DC38E.Attributes.KnownPersonDetected,
		ClusterConstants.DC38E.Attributes.StrangerDetected,
		ClusterConstants.DC38E.Attributes.HumanDetected,
		ClusterConstants.DC38E.Attributes.PetDetected,
		ClusterConstants.DC38E.Attributes.CarDetected,
	],
	StreamingCapabilities: {
		WebRTC: 0b1000,
		HLS: 0b0100,
		RTSP: 0b0010,
		MJPEG: 0b0001,
	},
	CameraSettingsSupportedAttributes: {
		C38A: [
			ClusterConstants.DC38A.Attributes.Flip,
			ClusterConstants.DC38A.Attributes.Mirror,
			ClusterConstants.DC38A.Attributes.OSDTimestamp,
			ClusterConstants.DC38A.Attributes.Motion,
			ClusterConstants.DC38A.Attributes.SDRecording,
			ClusterConstants.DC38A.Attributes.SDCardStatusTuya,
			ClusterConstants.DC38A.Attributes.SDCardUsageTuya,
			ClusterConstants.DC38A.Attributes.SupportedRecordingModes,
			ClusterConstants.DC38A.Attributes.NightVisionMode,
		],
		C38C: [
			ClusterConstants.DC38C.Attributes.Muted,
		],
	},
	CameraPanTiltDirections: {
		up: {
			cmdId: "04",
			value: 100,
		},
		down: {
			cmdId: "04",
			value: -100,
		},
		left: {
			cmdId: "03",
			value: -100,
		},
		right: {
			cmdId: "03",
			value: 100,
		},
	},
	GeofenceAccuracyLevels: [
		{
			value: "low",
			label: "clusters.DFFAD.settings.lowAccuracy",
			locationTracking: false,
			settings: {
				desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_VERY_LOW,
				geofenceModeHighAccuracy: false,
			} satisfies Partial<Config>,
		},
		{
			value: "medium",
			label: "clusters.DFFAD.settings.mediumAccuracy",
			locationTracking: false,
			settings: {
				desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
				geofenceModeHighAccuracy: false,
			} satisfies Partial<Config>,
		},
		{
			value: "high",
			label: "clusters.DFFAD.settings.highAccuracy",
			locationTracking: false,
			settings: {
				desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
				geofenceModeHighAccuracy: true,
			} satisfies Partial<Config>,
		},
		{
			value: "highest",
			label: "clusters.DFFAD.settings.highestAccuracy",
			locationTracking: true,
			settings: {
				desiredAccuracy: (Capacitor.getPlatform() === "android") ? BackgroundGeolocation.DESIRED_ACCURACY_HIGH : BackgroundGeolocation.DESIRED_ACCURACY_NAVIGATION, // see: https://transistorsoft.github.io/capacitor-background-geolocation/index.html#locationaccuracy
				geofenceModeHighAccuracy: true,
			} satisfies Partial<Config>,
		},
	],
	MovementTypes: {
		Absolute: 0,
		Relative: 1,
	},
	ClusterIds: {
		OrderList: [
			// Controls
			"F1F0", // Configure Subdevices
			"C38A", // Camera
			"0101", // DoorLock
			"FF9B", // Door Lock Password Management
			"0502", // Alarm
			"FF82", // Siren Maintenance
			"0006", // On/Off Toggle
			"FFAC", // Home Connect
			"0102", // Window Covering
			"0008", // Dim-Level
			"0300", // Light Color
			"0100", // Shade Configuration
			"FFAD", // Phone Location status
			"FF69", // MomitThermostat
			"FF80", // Z-Wave Thermostat
			"FF99", // Fan Mode
			"FF84", // Bosch Lawn Mower
			"FF96", // Pet Feeder
			"FF97", // Vaccum Robot
			// IR Controller / Remotes
			// "F200", // IR Controller / Remotes (only Mobile)
			// "F201", // IR Controller / Remotes (only Mobile)
			// "F202", // IR Controller / Remotes (only Mobile)
			// "F204", // IR Controller / Remotes (only Mobile)
			// "F205", // IR Controller / Remotes (only Mobile)
			// Units
			"0702", // Power
			"FD00", // Frient SmartMeter
			"0402", // Temperature
			"FD01", // PH
			"FD02", // OydoReductionPotential
			"FD03", // SaltConcentration
			"FD04", // TotalDissolvedSolids
			"FD05", // Water Temperature
			"0405", // Humidity
			"FF94", // CO2-Level
			"FF95", // VOC-Level
			"FF81", // CO-Sensor
			"FF9F", // PM2.5
			"0403", // Pressure
			"0400", // Luminance
			"000C", // Dew-Point
			// Notifications
			"FF92", // Nuki DoorLock
			"C38E", // Netatmo Camera Notifications
			"FF9E", // Netatmo Notifications
			"FF71", // Z-Wave Notification
			"FFAE", // Yale Notification
			"FF9C", // Gardena Notification
			// Configuration
			"FF9A", // Gas Sensor Maintenance
			"FF85", // Fibaro Configuration
			"FF83", // Steinel Settings
			"FC00", // Pet Immunity
			"FF9D", // Tuya Generic Cluster
		],
		Units: ["FF80", "000C", "0102", "0400", "0402", "0403", "0405", "0702", "FF81", "FF94", "FF95", "FF9F"],
		Actions: ["C38A", "0101", "0006", "0102", "FF69", "FF80"],
		PriorityActions: ["0502"],
		UsableIncaps: ["0006", "0101", "0400", "0402", "0403", "0405", "0500", "0702", "FF80", "FF81"],
		UsableOutcaps: ["0006", "C38A"],
	},
	QuickviewSettingsDefaultSectionsOrder: [
		"smartWidgets",
		"favoriteDevices",
		"favoriteScenes",
	],
	RoomIconIds: [
		"room_basement",
		"room_bathroom",
		"room_bedroom",
		"room_dining",
		"room_dressing",
		"room_family",
		"room_fitness",
		"room_gaming",
		"room_garage",
		"room_garden_shed",
		"room_generic",
		"room_hallway",
		"room_home_office",
		"room_kids",
		"room_kitchen",
		"room_lavatory",
		"room_living",
		"room_outdoor",
		"room_pool",
		"room_attic",
		"room_spa",
		"room_terrace",
		"room_utility",
	],
	PhoneStatus: [
		{
			value: 0,
			label: "clusters.DFFAD.phoneStatus.home",
		},
		{
			value: 1,
			label: "clusters.DFFAD.phoneStatus.away",
		},
	],
	PhoneModes: [
		{
			value: 0,
			label: "clusters.DFFAD.phoneModes.home",
		},
		{
			value: 1,
			label: "clusters.DFFAD.phoneModes.away",
		},
		{
			value: 2,
			label: "clusters.DFFAD.phoneModes.realtime",
		},
	],
	Scenes: {
		Mode: {
			Add: "add",
			Edit: "edit",
			Duplicate: "duplicate",
		},
		Icons: [
			"scene_alarm",
			"scene_bathroom",
			"scene_bedroom",
			"scene_book_reading",
			"scene_car",
			"scene_christmas_tree",
			"scene_clouds",
			"scene_cocktail",
			"scene_coffee_cup",
			"scene_dining",
			"scene_enter_room",
			"scene_exit_room",
			"scene_gaming",
			"scene_gift",
			"scene_heart",
			"scene_heating_cooling",
			"scene_home_office",
			"scene_house",
			"scene_kids",
			"scene_living",
			"scene_movie",
			"scene_music",
			"scene_night",
			"scene_outdoor",
			"scene_siren",
			"scene_star",
			"scene_suitcase",
			"scene_sun",
		],
	},
	ReviewUseCases: {
		TuyaDeviceAdded: "addTuyaDeviceSuccess",
		DeviceAdded: "addDeviceSuccess",
		RuleCreated: "addRuleSuccess",
		Devices: "devices",
		Rules: "rules",
	},
} as const;

export default constants;
